import * as React from "react";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import MainLayout from "src/layout";
import Helmet from "react-helmet";

// markup
const IndexPage = ({ data }) => {
  const puss = withArtDirection(getImage(data.puss), [
    {
      media: "(max-width: 1224px)",
      image: getImage(data.puss_head),
    },
  ]);
  const eleanor = withArtDirection(getImage(data.eleanor), [
    {
      media: "(max-width: 1224px)",
      image: getImage(data.eleanor_head),
    },
  ]);
  const fox = withArtDirection(getImage(data.fox), [
    {
      media: "(max-width: 1224px)",
      image: getImage(data.fox_head),
    },
  ]);
  const snow = withArtDirection(getImage(data.snow), [
    {
      media: "(max-width: 1224px)",
      image: getImage(data.snow_head),
    },
  ]);
  const red = withArtDirection(getImage(data.red), [
    {
      media: "(max-width: 1224px)",
      image: getImage(data.red_head),
    },
  ]);
  const wolf = withArtDirection(getImage(data.wolf), [
    {
      media: "(max-width: 1224px)",
      image: getImage(data.wolf_head),
    },
  ]);
  const pig = withArtDirection(getImage(data.pig), [
    {
      media: "(max-width: 1224px)",
      image: getImage(data.pig_head),
    },
  ]);
  const logo = withArtDirection(getImage(data.logo_long), [
    {
      media: "(max-width: 1224px)",
      image: getImage(data.logo),
    },
  ]);
  const blue = withArtDirection(getImage(data.blue_head), [
    {
      media: "(max-width: 1224px)",
      image: getImage(data.blue_head),
    },
  ]);

  return (
    <main>
      <Helmet title={`The Wolf is Dead | An Investigation Knowledge game`} />
      <MainLayout>
        <section className="section stripBlack mobileTopSection">
          <div className="container is-widescreen float-container">
            <div className="imagePanel">
              <div className="characterBox">
                <GatsbyImage image={wolf} className="flexItem" />
              </div>
              <div className="characterBox">
                <GatsbyImage image={blue} className="flexItem" />
              </div>
              <div className="characterBox characterBoxDesktop">
                <GatsbyImage image={puss} className="flexItem" />
              </div>
              <div className="characterBox characterBoxDesktop">
                <GatsbyImage image={snow} className="flexItem" />
              </div>
              <div className="logoBox">
                <GatsbyImage image={logo} />
              </div>
              <div className="characterBox characterBoxMobile characterBoxDesktop">
                <GatsbyImage image={pig} className="flexItem" />
              </div>
              <div className="characterBox characterBoxMobile characterBoxDesktop">
                <GatsbyImage image={eleanor} className="flexItem" />
              </div>
              <div className="characterBox characterBoxMobile">
                <GatsbyImage image={red} className="flexItem" />
              </div>
              <div className="characterBox characterBoxMobile">
                <GatsbyImage image={fox} className="flexItem" />
              </div>
              {/* <div className="steamButton button is-primary">
                Coming soon on Steam
              </div> */}
            </div>
          </div>
        </section>
        <div className="section boldDividerContainer">
          <iframe
            src="https://store.steampowered.com/widget/1786320/"
            frameborder="0"
            width="646"
            height="190"
            className="steamWidget"
          ></iframe>
        </div>
        {/* <section className="section">
          <div className="containerNarrow boldDivider">
            <h2>Time's up Mr Wolf.</h2>
          </div>
        </section> */}
        <section className="section">
          <div className="containerNarrow">
            <h2 className="spaced">What a big heart you have</h2>
            <p>
              Alexander Wolff had gotten out of jail and turned his life around.
              He'd worked with the community, he'd found redemption, he'd become
              a writer. And the book of his life was becoming a film.
            </p>
            <p>
              The cast and the crew gathered at 8 Drury Lane to celebrate the
              final cut of the movie.
            </p>
            <p>
              At 11:08PM Puss found his body floating face down in the pool.
            </p>
            <p>At 11:10PM the police were called.</p>
            <p>At 11:18PM you arrive.</p>
            <h2 className="spaced">Solve a murder</h2>
            <p>
              The Wolf is Dead is free form, first person detective game. If you
              liked games like the Obra Dinn &amp; Paradise Killer this should
              be right up your street.
            </p>
          </div>
        </section>
        <div className="container ">
          <h2 className="headerStyle centerText p-b-lg">
            Now on Steam! -{" "}
            <a href="https://store.steampowered.com/app/1786320/The_Wolf_Is_Dead/">
              The Wolf Is Dead
            </a>
          </h2>
        </div>
      </MainLayout>
    </main>
  );
};

export default IndexPage;

// logo: file(relativePath: {eq: "puss.jpg"}) {
//   id
//   childImageSharp {
//     id
//   }
// }
// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
  query IndexQuery {
    puss: file(relativePath: { eq: "puss_desktop_long_1550.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 0.4)
      }
    }
    puss_head: file(relativePath: { eq: "puss_head.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 1)
      }
    }
    charming: file(relativePath: { eq: "charming_desktop_long_1550.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 1)
      }
    }

    eleanor: file(relativePath: { eq: "eleanor_desktop_long_1550.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 0.4)
      }
    }
    eleanor_head: file(relativePath: { eq: "eleanor_head.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 1)
      }
    }
    fox: file(relativePath: { eq: "fox.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 0.4)
      }
    }
    fox_head: file(relativePath: { eq: "fox_head.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 1)
      }
    }
    red: file(relativePath: { eq: "red.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 0.4)
      }
    }
    red_head: file(relativePath: { eq: "red_head.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 1)
      }
    }
    blue_head: file(relativePath: { eq: "blue_head.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 1)
      }
    }
    wolf: file(relativePath: { eq: "wolf.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 0.4)
      }
    }
    wolf_head: file(relativePath: { eq: "wolf_head.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 1)
      }
    }
    snow: file(relativePath: { eq: "snow.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 0.4)
      }
    }
    snow_head: file(relativePath: { eq: "snow_head.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 1)
      }
    }
    pig: file(relativePath: { eq: "pig_desktop_long_1550.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 0.4)
      }
    }
    pig_head: file(relativePath: { eq: "pig_head.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 1)
      }
    }
    logo: file(relativePath: { eq: "logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 452, aspectRatio: 1)
      }
    }
    logo_long: file(relativePath: { eq: "logo-long2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800, aspectRatio: 0.4)
      }
    }
  }
`;
//     eleanor: file(relativePath: { eq: "images/eleanor.jpeg" }) {
//       childImageSharp {
//         fluid(maxWidth: 890, maxHeight: 890) {
// 					...GatsbyImageSharpFluid
// 				}
//       }
//     }
//     fox: file(relativePath: { eq: "images/fox.jpeg" }) {
//       childImageSharp {
//         fluid(maxWidth: 890, maxHeight: 890) {
// 					...GatsbyImageSharpFluid
// 				}
//       }
//     }
//     red: file(relativePath: { eq: "images/red.jpeg" }) {
//       childImageSharp {
//         fluid(maxWidth: 890, maxHeight: 890) {
// 					...GatsbyImageSharpFluid
// 				}
//       }
//     }
//     puss: file(relativePath: { eq: "images/puss.jpeg" }) {
//       childImageSharp {
//         fluid(maxWidth: 890, maxHeight: 890) {
// 					...GatsbyImageSharpFluid
// 				}
//       }
//     }
//     snow: file(relativePath: { eq: "images/snow.jpeg" }) {
//       childImageSharp {
//         fluid(maxWidth: 890, maxHeight: 890) {
// 					...GatsbyImageSharpFluid
// 				}
//       }
//     }
//     wolf: file(relativePath: { eq: "images/wolf.jpeg" }) {
//       childImageSharp {
//         fluid(maxWidth: 890, maxHeight: 890) {
// 					...GatsbyImageSharpFluid
// 				}
//       }
//     }
//   }
// `
